<template>
  <div class="login">
    <button @click="authenticate('google')">Authenticate with Google</button>
    <span>Because I certainly can't be arsed to do password storage</span>
  </div>
</template>

<script>
// @ is an alias to /src
import Vue from 'vue'

export default {
  name: 'Login',
  components: {
  },
  methods: {
    authenticate() {
      Vue.googleAuth().directAccess()
      Vue.googleAuth().signIn((googleUser) => {
        const idToken = googleUser.getAuthResponse().id_token
        this.$store.commit('authorize', idToken)
        this.$router.push('/')
      }, (error) => {
        console.error(error)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  button {
    font-size: 20px;
    padding: 5px 10px;
  }
  
  span {
    padding: 10px;
  }
}
</style>

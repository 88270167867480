<template>
  <div :class="cssClasses" @click="toggleEditor()">
    <RequestEditor v-if="showEditor" :request="request"/>
    <div class="name">
      {{ request.name }}
    </div>
    <div class="level">
      {{ request.level }}
    </div>
    <div class="languages">
      {{ request.languages }}
    </div>
  </div>
</template>

<script>
import RequestEditor from '@/components/RequestEditor.vue'

export default {
  name: 'Request',
  data() {
    return {
      showEditor: false
    }
  },
  components: {
    RequestEditor
  },
  props: ['request'],
  computed: {
    cssClasses() {
      return `request ${this.request.status}`
    }
  },
  methods: {
    toggleEditor() {
      this.showEditor = !this.showEditor
    }
  }
}
</script>

<style lang="less" scoped>
.request {
  border: 2px solid #ccc;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 5px;
  margin: 10px;

  &.accepted {
    background-color: #343;
  }

  &.denied {
    background-color: #522;
  }

  &.blocked {
    background-color: #355;
  }

  &.notfound {
    background-color: #553;
  }

  &.downgraded {
    background-color: #550;
  }

  &.undefined {
    border-color: #555;
    color: #555;
  }

  .name {
    font-size: 30px;
  }

}  
</style>

<template>
  <div class="background">
    <div class="history" @click="$event.stopPropagation()">
      <span class="confirm" v-if="clipboardConfirm">Copied to clipboard!</span>
      <span v-if="history.length === 0">No history found</span>
      <span v-for="historyElement in history" :key="historyElement">{{ historyElement }}</span>
      <div class="buttons">
        <button
          v-clipboard:copy="historyText"
          v-clipboard:success="showClipboardConfirm">
          Copy History
        </button>
        <button @click="clearHistory">Clear History</button>
      </div>
    </div>
  </div>
</template>

<script>
export default{
  name: 'History',
  data() {
    return {
      clipboardConfirm: false
    }
  },
  computed: {
    history() {
      const names = Object.keys(this.$store.state.history)
      return names.map(name => {
        return `${name}: ${this.$store.state.history[name].oldStatus} => ${this.$store.state.history[name].newStatus}`
      })
    },
    historyText() {
      return this.history.join('\n')
    }
  },
  methods: {
    clearHistory() {
      this.$store.commit('clearHistory')
    },
    showClipboardConfirm() {
      this.clipboardConfirm = true
      setTimeout(() => {
        this.clipboardConfirm = false
      }, 3000)
    }
  }
}
</script>

<style lang="less" scoped>
.background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,.5);
}

.history {
  position: relative;
  border: 2px solid #ccc;
  width: 80vw;
  padding: 20px;
  margin: 20vh auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 5px;
  background-color: #333;

  .label {
    font-size: 20px;
    color: #eee;
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }

  .value {
    text-align: left;
  }

  .buttons {
    margin-top: 20px;

    button {
      margin-right: 10px;

      &.active {
        background-color: #777;
      }
    }
  }

  .confirm {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30px;
    line-height: 30px;
    background-color: rgba(0,155,0,.9);
    text-align: center;
  }
}  
</style>

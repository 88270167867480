<template>
  <div class="home">
    <div class="controls">
      <div @click="toggleHistory">
        <button>Show History</button>
        <History v-if="this.showHistory"/>
      </div>
      <div class="filters">
        <button
          @click="toggleFilter('open')"
          v-bind:class="{ active: this.$store.state.filter.indexOf('open') !== -1 }">
          open
        </button>
        <button
          @click="toggleFilter('accepted')"
          v-bind:class="{ active: this.$store.state.filter.indexOf('accepted') !== -1 }">
          accepted
        </button>
        <button
          @click="toggleFilter('denied')"
          v-bind:class="{ active: this.$store.state.filter.indexOf('denied') !== -1 }">
          denied
        </button>
        <button
          @click="toggleFilter('blocked')"
          v-bind:class="{ active: this.$store.state.filter.indexOf('blocked') !== -1 }">
          blocked
        </button>
        <button
          @click="toggleFilter('notfound')"
          v-bind:class="{ active: this.$store.state.filter.indexOf('notfound') !== -1 }">
          not found
        </button>
        <button
          @click="toggleFilter('downgraded')"
          v-bind:class="{ active: this.$store.state.filter.indexOf('downgraded') !== -1 }">
          downgraded
        </button>
      </div>
      <button class="refresh" @click="refresh()">↻</button>
    </div>
    <div class="requests">
      <Request
        v-for="request in filteredRequests"
        :key="request.id"
        :request="request" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Request from '@/components/Request.vue'
import History from '@/components/History.vue'

export default {
  name: 'Home',
  components: {
    Request,
    History
  },
  data() {
    return {
      requests: [],
      showHistory: false
    }
  },
  computed: {
    filteredRequests() {
      return this.$store.state.requests.filter(el => this.$store.state.filter.indexOf(el.status) !== -1)
    }
  },
  mounted() {
    // eslint-disable-next-line func-names
    this.$nextTick(function() {
      this.refresh()
    })
  },
  methods: {
    toggleHistory() {
      this.showHistory = !this.showHistory
    },
    toggleFilter(type) {
      this.$store.commit('toggleFilter', type)
    },
    refresh() {
      this.$api.get('', {
        headers: {
          Authorization: `Bearer ${this.$store.state.token}`
        }
      }).then(res => {
        this.$store.commit('updateRequests', res.data)
      })
        .catch((e) => {
          if (e.response.status === 401) {
            Vue.googleAuth().signOut(() => { console.warn('Signed out!') })
            this.$store.commit('unauthorize')
            this.$router.push('login')
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
.controls {
  height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.requests {
  display: flex;
  width: 95vw;
  height: 80vh;
  height: 80vh;
  flex-wrap: wrap;
  overflow-y: scroll;
  margin: 0 auto;
}  
.refresh {
  font-size: 30px;
  margin: 15px;
}

button {
  margin: 5px;
  font-size: 20px;
  padding: 5px 10px;

  &.active {
    background-color: #777;
  }
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    filter: ['accepted', 'denied', 'open', 'blocked', 'notfound', 'downgraded'],
    requests: [],
    history: {}
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('googleToken') != null) {
        state.token = localStorage.getItem('googleToken')
      }
      if (localStorage.getItem('filter') != null) {
        state.filter = JSON.parse(localStorage.getItem('filter'))
      }
      if (localStorage.getItem('history') != null) {
        state.history = JSON.parse(localStorage.getItem('history'))
      }
    },
    authorize(state, token) {
      localStorage.setItem('googleToken', token)
      state.token = token
    },
    unauthorize(state) {
      localStorage.setItem('googleToken', null)
      state.token = null
    },
    toggleFilter(state, item) {
      const addItem = state.filter.indexOf(item) === -1
      if (addItem) {
        state.filter.push(item)
      } else {
        state.filter = state.filter.filter(el => el !== item)
      }
      localStorage.setItem('filter', JSON.stringify(state.filter))
    },
    updateRequests(state, requests) {
      state.requests = requests
    },
    updateRequest(state, request) {
      state.requests = state.requests.map(el => ((el.id === request.id) ? request : el))
    },
    changeHistory(state, { name, oldStatus, newStatus }) {
      if (typeof state.history[name] !== 'object') {
        state.history[name] = {
          oldStatus,
          newStatus
        }
      } else {
        state.history[name].newStatus = newStatus
      }
      if (state.history[name].newStatus === state.history[name].oldStatus) {
        delete state.history[name]
      }
      localStorage.setItem('history', JSON.stringify(state.history))
    },
    clearHistory(state) {
      state.history = {}
      localStorage.setItem('history', JSON.stringify(state.history))
    }
  },
  actions: {
  },
  modules: {
  }
})

<template>
  <div class="background">
    <div class="requestEditor" @click="$event.stopPropagation()">
      <span class="label">Name:</span>
      <span class="value">{{ request.name }}</span>
      <span class="label">Level:</span>
      <span class="value">{{ request.level }}</span>
      <span class="label">Languages:</span>
      <span class="value">{{ request.languages }}</span>
      <span class="label">Other Topics:</span>
      <span class="value">{{ request.other }}</span>
      <span class="label">References:</span>
      <div class="value" v-linkified v-html="request.references" />
      <span class="label">Examples of Helping:</span>
      <div class="value" v-linkified v-html="request.helping" />
        <span class="label">Notes:</span>
        <span class="value">{{ request.notes }}</span>
        <span class="label">Status:</span>
        <div class="buttons">
          <button
            @click="setStatus('open')"
            :class="{ active: this.request.status === 'open' }">
            open
          </button>
          <button
            @click="setStatus('accepted')"
            :class="{ active: this.request.status === 'accepted' }">
            accepted
          </button>
          <button
            @click="setStatus('denied')"
            :class="{ active: this.request.status === 'denied' }">
            denied
          </button>
          <button
            @click="setStatus('blocked')"
            :class="{ active: this.request.status === 'blocked' }">
            blocked
          </button>
          <button
            @click="setStatus('notfound')"
            :class="{ active: this.request.status === 'notfound' }">
            not found
          </button>
          <button
            @click="setStatus('downgraded')"
            :class="{ active: this.request.status === 'downgraded' }">
            downgraded
          </button>
        </div>
      </div>
    </div>
</template>

<script>
import Vue from 'vue'

export default{
  name: 'RequestEditor',
  props: ['request'],
  methods: {
    setStatus(status) {
      this.$store.commit('changeHistory', {
        name: this.request.name,
        oldStatus: this.request.status,
        newStatus: status
      })
      this.request.status = status
      this.$store.commit('updateRequest', this.request)
      this.$api.put('request', {
        request: this.request
      },
      {
        headers: {
          Authorization: `Bearer ${this.$store.state.token}`
        }
      })
        .catch((e) => {
          if (e.response.status === 401) {
            Vue.googleAuth().signOut(() => { console.warn('Signed out!') })
            this.$store.commit('unauthorize')
            this.$router.push('login')
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
.background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,.5);
}

.requestEditor {
  border: 2px solid #ccc;
  width: 80vw;
  padding: 20px;
  margin: 20vh auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 5px;
  background-color: #333;

  .label {
    font-size: 20px;
    color: #eee;
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }

  .value {
    text-align: left;
  }

  .buttons {
    button {
      margin-right: 10px;

      &.active {
        background-color: #777;
      }
    }
  }
}  
</style>

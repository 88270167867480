import Vue from 'vue'
import GoogleAuth from 'vue-google-oauth'
import VueRest from 'vue-rest'
import linkify from 'vue-linkify'
import VueClipboard from 'vue-clipboard2'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  beforeCreate() { this.$store.commit('initializeStore') },
  render: (h) => h(App)
}).$mount('#app')
Vue.use(GoogleAuth, { client_id: '1090934172679-1i4l1tmre30jti8eevj1l0539rj58qj1.apps.googleusercontent.com' })
Vue.googleAuth().load()
Vue.use(VueClipboard)
Vue.directive('linkified', linkify)
Vue.use(VueRest, {
  axiosOptions: {
    baseURL: 'https://api.simmarith.com/codereview/'
  }
})
